import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import request from 'graphql-request';
import Cookies from 'js-cookie';
import axios from 'axios';
import { usePathname } from 'next/navigation';
import { Dispatch, useEffect } from 'react';
import moment from 'moment';

import {
  checkoutFragment,
  Consolidate_Cart_Item_V2,
  Consolidate_ProductVariant,
  Curaql,
  Curaql_OrderType,
  DatalayerAnalytics,
  DutchiePlus_Generated_Checkout,
  DutchiePlus_OrderType,
  DutchiePlusService
} from 'services';
import { SiteWideWrapperProps } from './siteWideContext';
import { snackbarMessage } from '../utils/snackbar';
import { dutchieCartToConsolidateCartMap } from './siteWideContext/helpers';
import { checkoutMetaMap } from '../utils/checkoutMetaMap';
import { useStorage } from './useStorage';
import { ProductListTilePopupProps } from '../components/ProductListTile';
import { AxiosData } from './graphs';

type DutchieCartDataProps = Pick<
  SiteWideWrapperProps,
  | 'isLoggedIn'
  | 'selectedDispensary'
  | 'selectedDispensaryID'
  | 'selectedDispensaryLoading'
  | 'user'
  | 'userMenuType'
> & {
  setPopupProduct: SiteWideWrapperProps['popup']['setProduct'];
  setShowPopup: SiteWideWrapperProps['popup']['setShow'];
  updateUserOrderTypeLocalStorage: Dispatch<Curaql_OrderType>;
  userLoading: boolean;
};

export type DutchieAddToCartProps = {
  checkoutId?: string;
  dispensaryUniqueId: string;
  product: ProductListTilePopupProps['item']['product'];
  quantity: number;
  retailerId: string;
  variant: Consolidate_ProductVariant;
  analyticsContext: {
    pathname: string;
    categoryKey?: string[] | string;
    index?: number;
  };
  cb: () => void;
  ignoreErrors?: boolean;
};

export type DutchieRemoveFromCartProps = {
  cartItem: Consolidate_Cart_Item_V2;
  cb: () => void;
  dispensaryUniqueId: string;
  retailerId: string;
};

type CartMeta = {
  checkoutId: string;
  dispensaryUniqueId: string;
  isUserCart: boolean;
  itemQuantity: number;
  items:
    | DutchiePlus_Generated_Checkout['items']
    | Consolidate_Cart_Item_V2[] /* used on login to update cart meta data based on updatedAt and items.length */;
  menuType: 'RECREATIONAL' | 'MEDICAL';
  updatedAt: string;
};

export type UpdateUserDBCartProps = {
  checkoutId: string;
  dispensaryUniqueId: string;
  pricingType: 'RECREATIONAL' | 'MEDICAL';
  redirectUrl: string;
  onSuccessCallback?: () => void;
};

export type UpdateUserDBCartData = {
  updateUserDBCart: {
    success: true;
  };
};

export type DutchieUpdateOrderTypeProps = {
  orderType: DutchiePlus_OrderType;
  dispensaryUniqueId: string;
};

export const useDutchieCartData = ({
  isLoggedIn,
  selectedDispensary,
  selectedDispensaryLoading,
  setPopupProduct,
  setShowPopup,
  user,
  userLoading,
  userMenuType,
  updateUserOrderTypeLocalStorage
}: DutchieCartDataProps) => {
  const baseUrl = process.env.DUTCHIE_PLUS_URL as string;
  const requestHeaders = {
    authorization: `Bearer public-${process.env.DUTCHIE_PLUS_PUBLIC_TOKEN}`
  };
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const [emails] = useStorage<string[]>('employeeEmails', []);

  const defaultMenuType =
    Array.isArray(selectedDispensary?.menuTypes) &&
    selectedDispensary.menuTypes.includes(userMenuType)
      ? userMenuType
      : selectedDispensary?.menuTypes[0];
  let cartMeta = Cookies.get(Curaql.CART_META_KEY);
  const parsedCartMeta: CartMeta | null = cartMeta
    ? JSON.parse(cartMeta)
    : null;
  let metaCheckoutId =
    parsedCartMeta && parsedCartMeta.checkoutId
      ? parsedCartMeta.checkoutId
      : '';
  const isUserCart =
    parsedCartMeta && parsedCartMeta.isUserCart
      ? parsedCartMeta.isUserCart
      : false;
  const menuType =
    parsedCartMeta && parsedCartMeta.menuType
      ? parsedCartMeta.menuType
      : defaultMenuType;

  const {
    data: cart,
    error,
    isFetching,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['dutchie_cart'],
    queryFn: async () => {
      const currCartMeta = Cookies.get(Curaql.CART_META_KEY);
      const parsedCurrCartMeta: CartMeta | null = currCartMeta
        ? JSON.parse(currCartMeta)
        : null;

      if (selectedDispensary?.retailerId && metaCheckoutId) {
        if (
          selectedDispensary?.uid === parsedCurrCartMeta?.dispensaryUniqueId
        ) {
          const checkoutData = await request(
            baseUrl,
            `query Checkout(
              $retailerId: ID!
              $checkoutId: ID!
            ) {
              checkout(
                retailerId: $retailerId
                id: $checkoutId
              ) {
                ${checkoutFragment}
              }
            }`,
            {
              checkoutId: metaCheckoutId,
              retailerId: selectedDispensary.retailerId || ''
            },
            requestHeaders
          )
            .then(
              (res: any /* Typescript: need to codegen graphql schemas */) => {
                const newCartData: DutchiePlus_Generated_Checkout =
                  res.checkout;
                return dutchieCartToConsolidateCartMap(
                  newCartData,
                  selectedDispensary.uid
                );
              }
            )
            .catch((err) => {
              console.error('query dutchie_cart', err);
              const message = err.response?.data?.errors?.length
                ? err.response.data.errors[0].message
                : err.toString();
              DatalayerAnalytics.pushErrorEvent({
                category: 'api',
                location: 'getCart',
                description: message,
                consolidateDispensary: selectedDispensary || undefined
              });
              throw new Error(err);
            });
          return checkoutData;
        } else {
          clearCartMeta();
        }
      }
      return null;
    },
    enabled: !!cartMeta && !!selectedDispensary
  });

  const updateUserDBCartCuraql = useMutation({
    mutationFn: (variables: UpdateUserDBCartProps) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.UPDATE_USER_DB_CART,
          variables
        },
        withCredentials: true
      });
    },
    onSuccess: (
      { data }: AxiosData<UpdateUserDBCartData>,
      { onSuccessCallback }
    ) => {
      if (data.data.updateUserDBCart.success) {
        onSuccessCallback ? onSuccessCallback() : null;
      }
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      }
    },
    onError: (error: any) => {
      console.error('updateUserDBCart: ', error);
      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'updateUserDBCart',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  useEffect(() => {
    // clear cart if cart's dispensary doesn't match the selected dispensary
    if (
      cart &&
      !selectedDispensaryLoading &&
      selectedDispensary &&
      (cart.dispensaryUniqueId !== selectedDispensary.uid ||
        pathname.includes('order-confirmation'))
    ) {
      clearCart();
    }
  }, [pathname, cart, userMenuType, selectedDispensary?.uid]);

  useEffect(() => {
    // clear cart meta if user cart meta exists after logout
    if (!userLoading && !isLoggedIn && isUserCart) {
      clearCartMeta();
    }
  }, [isLoggedIn, userLoading]);

  const addItemToCart = (variables: DutchieAddToCartProps) => {
    if (cart) {
      const matchingCartItem = cart.items.find(
        (item) => item.product.id === variables.product.id
      );
      if (
        matchingCartItem &&
        variables.variant.quantity &&
        variables.variant.quantity <
          matchingCartItem.quantity + variables.quantity
      ) {
        variables.cb();
        return snackbarMessage('Added quantity exceeds product inventory.');
      }
    }
    if (metaCheckoutId) {
      return addItems.mutateAsync(variables);
    } else {
      return createCheckout.mutateAsync(variables);
    }
  };

  const addItems = useMutation({
    mutationFn: async (variables: DutchieAddToCartProps) => {
      return request(
        baseUrl,
        `mutation AddItemToCheckout(
          $checkoutId: ID!
          $option: String!
          $productId: ID!
          $quantity: Int!
          $retailerId: ID!
        ) {
          addItem(
            checkoutId: $checkoutId
            option: $option
            productId: $productId
            quantity: $quantity
            retailerId: $retailerId
          ) {
            ${checkoutFragment}
          }
        }`,
        {
          checkoutId: variables.checkoutId || metaCheckoutId,
          option: variables.variant.option,
          productId: variables.product.id,
          quantity: variables.quantity,
          retailerId: variables.retailerId
        },
        requestHeaders
      );
    },
    onSuccess: (
      data: any /* Typescript: need to codegen graphql schemas */,
      {
        analyticsContext,
        cb,
        dispensaryUniqueId,
        ignoreErrors = false,
        product,
        quantity,
        variant
      }
    ) => {
      const newCartData: DutchiePlus_Generated_Checkout = data.addItem;
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(newCartData, dispensaryUniqueId)
      );
      /* Cart Popup - should not show on cart page */
      if (
        newCartData.items[0] &&
        !analyticsContext.pathname.includes('cart') &&
        !ignoreErrors
      ) {
        setPopupProduct({
          product: product,
          variant: variant,
          quantity: quantity
        });
        setShowPopup(true);
      }
      cb();

      /* Update Cart Meta */
      let newQuantity = 0;
      newCartData.items.map((item) => {
        newQuantity += item.quantity;
      });

      const newCookies: CartMeta = {
        checkoutId: newCartData.id,
        dispensaryUniqueId,
        isUserCart: isLoggedIn,
        itemQuantity: newQuantity,
        items: newCartData.items,
        menuType: newCartData.pricingType,
        updatedAt: moment(newCartData.updatedAt).format('x')
      };
      Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));

      /* Update user's cart in Mongo */
      if (isLoggedIn && user) {
        const newCartData = data.addItem;
        updateUserDBCartCuraql?.mutate({
          dispensaryUniqueId,
          checkoutId: newCartData.id,
          pricingType: newCartData.pricingType,
          redirectUrl: newCartData.redirectUrl
        });
      }

      /* Analytics */
      if (!ignoreErrors) {
        const generatedAnalyticsContext =
          DatalayerAnalytics.generateAnalyticsContext(
            analyticsContext.pathname,
            selectedDispensary?.shopLink || '',
            '',
            '',
            { key: `${analyticsContext.categoryKey}` },
            analyticsContext.index
          );

        const mappedAnalyticsContext = {
          context: {
            item_variant: variant,
            item_list_id: generatedAnalyticsContext.list.id,
            item_list_name: generatedAnalyticsContext.list.name,
            index: analyticsContext.index
          },
          consolidateDispensary: selectedDispensary,
          product,
          quantity // only the amount added to cart per event, not the total
        };

        if (quantity > 0) {
          DatalayerAnalytics.pushAddToCart(mappedAnalyticsContext);
        } else {
          DatalayerAnalytics.pushRemoveFromCart(mappedAnalyticsContext);
        }
      }
    },
    onError: (err: any, { cb, ignoreErrors = false }) => {
      cb();
      if (!ignoreErrors) {
        const errorMessage = err.response?.errors[0].message;
        console.error('addItems', errorMessage);
        snackbarMessage(
          errorMessage &&
            (errorMessage.includes('Failed') || errorMessage.includes('Sorry'))
            ? errorMessage.replace('Error: ', '')
            : 'Something went wrong, please try again.',
          'error'
        );
        DatalayerAnalytics.pushErrorEvent({
          category: errorMessage.includes('purchase limit')
            ? 'expected'
            : 'api',
          location: 'addToCart',
          description: errorMessage,
          consolidateDispensary: selectedDispensary || undefined
        });
      }
    }
  });

  const createCheckout = useMutation({
    mutationFn: async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      variables: DutchieAddToCartProps /* unused arg needs to be passed to onSuccess to addItems */
    ) => {
      return request(
        baseUrl,
        `mutation CreateCheckout(
          $address: CheckoutAddressInput
          $metadata: JSON
          $orderType: OrderType!
          $pricingType: PricingType!
          $retailerId: ID!
        ) {
          createCheckout(
            address: $address
            metadata: $metadata
            orderType: $orderType
            pricingType: $pricingType
            retailerId: $retailerId
          ) {
            ${checkoutFragment}
          }
        }`,
        {
          metadata: checkoutMetaMap(user, emails),
          orderType: process.env.IS_KIOSK === 'true' ? 'KIOSK' : 'PICKUP',
          pricingType: menuType,
          retailerId: selectedDispensary?.retailerId || ''
        },
        requestHeaders
      );
    },
    onSuccess: (
      data: any /* Typescript: need to codegen graphql schemas */,
      variables
    ) => {
      const newCartData: DutchiePlus_Generated_Checkout = data.createCheckout;
      if (newCartData) {
        const newCookies: CartMeta = {
          checkoutId: newCartData.id,
          dispensaryUniqueId: variables.dispensaryUniqueId,
          isUserCart: isLoggedIn,
          itemQuantity: 0,
          items: newCartData.items,
          menuType: newCartData.pricingType,
          updatedAt: moment(newCartData.updatedAt).format('x')
        };
        Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));
        return addItems.mutateAsync({
          ...variables,
          checkoutId: newCartData.id
        });
      }
    },
    onError: (err: any, variables) => {
      console.error('createCheckout', err);
      variables.cb();
      //clear cart meta and cache
      const message = err.response?.data?.errors?.length
        ? err.response.data.errors[0].message
        : err.toString();
      snackbarMessage(
        error?.message &&
          (error.message.includes('Failed') || error.message.includes('Sorry'))
          ? error.message.replace('Error: ', '')
          : 'Something went wrong, please try again.',
        'error'
      );
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'createCheckout',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  const removeItemFromCart = useMutation({
    mutationFn: async (variables: DutchieRemoveFromCartProps) => {
      return request(
        baseUrl,
        `mutation RemoveItemFromCheckout( 
          $checkoutId: ID!
          $itemId: ID!
          $retailerId: ID!
        ) {
          removeItem(
            checkoutId: $checkoutId
            itemId: $itemId
            retailerId: $retailerId
          ) {
            ${checkoutFragment}
          }
        }`,
        {
          checkoutId: metaCheckoutId,
          itemId: variables.cartItem.id,
          retailerId: variables.retailerId
        },
        requestHeaders
      );
    },
    onSuccess: (data: any, { cartItem, cb, dispensaryUniqueId }) => {
      /* Update cart cache data */
      const newCartData: DutchiePlus_Generated_Checkout = data.removeItem;
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(newCartData, dispensaryUniqueId)
      );
      cb();

      /* Update cart meta */
      let newQuantity = 0;
      newCartData.items.map((item) => {
        newQuantity += item.quantity;
      });

      const newCookies: CartMeta = {
        checkoutId: newCartData.id,
        dispensaryUniqueId,
        isUserCart: isLoggedIn,
        itemQuantity: newQuantity,
        items: newCartData.items,
        menuType: newCartData.pricingType,
        updatedAt: moment(newCartData.updatedAt).format('x')
      };
      Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));

      /* Update user's cart DB */
      if (isLoggedIn && user) {
        updateUserDBCartCuraql.mutate({
          checkoutId: newCartData.id,
          dispensaryUniqueId: dispensaryUniqueId,
          pricingType: newCartData.pricingType,
          redirectUrl: newCartData.redirectUrl
        });
      }

      /* Analytics */
      const selectedVariant =
        cartItem.product.variants.length > 1
          ? cartItem.product.variants.find((v) => v.option === cartItem.option)
          : cartItem.product.variants[0];
      if (selectedVariant) {
        DatalayerAnalytics.pushRemoveFromCart({
          product: cartItem.product,
          context: {
            item_variant: selectedVariant,
            item_list_id: undefined,
            item_list_name: undefined,
            index: undefined
          },
          quantity: cartItem.quantity
        });
      }
    },
    onError: (error: any, { cb }) => {
      cb();
      const errorMessage = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      console.error('removeItem: ', errorMessage);
      cb();
      snackbarMessage(
        errorMessage &&
          (errorMessage.includes('Failed') || errorMessage.includes('Sorry'))
          ? errorMessage
          : 'Something went wrong, please try again.'
      );
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'removeItem',
        description: errorMessage,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  const clearCartMeta = (cb?: () => void) => {
    Cookies.remove(Curaql.CART_META_KEY);
    cartMeta = undefined;
    metaCheckoutId = '';

    // set timeout because race condition for cartMeta (might fetch with previous cart meta before it is removed)
    setTimeout(() => {
      queryClient.resetQueries({
        queryKey: ['dutchie_cart'],
        exact: true
      });
    }, 100);
    cb ? cb() : null;
  };

  const clearUserCartCuraql = useMutation({
    mutationFn: () => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.CLEAR_USER_CART
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      }
    },
    onError: (error: Error | any) => {
      console.error('clearUserCart: ', error);
      const message = error.response.data.errors.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'clearUserCart',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message
          : 'Something went wrong, please try again.'
      );
    }
  });

  const clearCart = async (cb?: () => void) => {
    clearCartMeta();
    cb && cb();
    if (isLoggedIn) {
      await clearUserCartCuraql.mutateAsync();
    }
  };

  const updateCartOrderType = useMutation({
    mutationFn: async (variables: DutchieUpdateOrderTypeProps) => {
      return request(
        baseUrl,
        `mutation UpdateCheckout(
          $retailerId: ID!
          $checkoutId: ID!
          $orderType: OrderType!
          $pricingType: PricingType!
          $metadata: JSON
        ) {
          updateCheckout(
            checkoutId: $checkoutId
            retailerId: $retailerId
            orderType: $orderType
            pricingType: $pricingType, 
            metadata: $metadata) {
              ${checkoutFragment}
          }
        }`,
        {
          checkoutId: metaCheckoutId,
          retailerId: selectedDispensary?.retailerId || '',
          orderType: variables.orderType,
          pricingType: userMenuType,
          metadata: checkoutMetaMap(user)
        },
        requestHeaders
      );
    },
    onSuccess: (data: any, variables: DutchieUpdateOrderTypeProps) => {
      updateUserOrderTypeLocalStorage(variables.orderType);
      const newCartData: DutchiePlus_Generated_Checkout = data.updateCheckout;
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(
          newCartData,
          variables.dispensaryUniqueId
        )
      );
    },
    onError: (error: any) => {
      console.error('updateOrderType: ', error);
      const errorMessage = error.response?.errors[0].message;
      snackbarMessage(
        errorMessage &&
          (errorMessage.includes('Failed') || errorMessage.includes('Sorry'))
          ? errorMessage
          : 'Something went wrong, please try again.'
      );
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'updateOrderType',
        description: errorMessage,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  const mergeCart = async (email: string) => {
    const oldCart = cart;
    /* have to check email since user is still loading */
    if (email.length && selectedDispensary && oldCart) {
      if (oldCart.items.length) {
        /* cookies is replaced with user's db cart on login */
        const newCartMeta = Cookies.get(Curaql.CART_META_KEY);
        const parsedNewCartMeta: CartMeta | null = newCartMeta
          ? JSON.parse(newCartMeta)
          : null;
        let newCartDutchieRes = null;

        if (
          oldCart.id !== parsedNewCartMeta?.checkoutId &&
          parsedNewCartMeta?.checkoutId &&
          selectedDispensary.uid === parsedNewCartMeta.dispensaryUniqueId
        ) {
          const dutchiePlus = new DutchiePlusService();
          newCartDutchieRes = await dutchiePlus.getCheckout(
            selectedDispensary.retailerId,
            parsedNewCartMeta.checkoutId
          );
        }

        if (
          // replace user's cart with local cart when user cart exists but user.cart.items.length = 0
          (newCartDutchieRes?.success &&
            newCartDutchieRes.data.items.length <= 0) ||
          // replace user's cart with local cart when user cart does not exist. (note: user data has not loaded in yet so user.cart check is not possible)
          parsedNewCartMeta?.checkoutId === oldCart.id ||
          // replace user's cart with local cart when the user's cart's dispensary uid does not match selectedDispensary
          parsedNewCartMeta?.dispensaryUniqueId !== selectedDispensary.uid
        ) {
          if (oldCart.items.length) {
            try {
              updateUserDBCartCuraql.mutateAsync({
                dispensaryUniqueId: selectedDispensary.uid,
                checkoutId: oldCart.id,
                pricingType: oldCart.pricingType,
                redirectUrl: oldCart.redirectUrl,
                onSuccessCallback: () => {
                  queryClient.setQueryData(['dutchie_cart'], oldCart);
                  /* Set Cookies */
                  let newQuantity = 0;
                  oldCart.items.map((item) => {
                    newQuantity += item.quantity;
                  });

                  const newCookies: CartMeta = {
                    checkoutId: oldCart.id,
                    dispensaryUniqueId: selectedDispensary.uid,
                    isUserCart: isLoggedIn,
                    itemQuantity: newQuantity,
                    items: oldCart.items,
                    menuType: oldCart.pricingType,
                    updatedAt: moment(oldCart.updatedAt).format('x')
                  };
                  Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));
                }
              });
              return;
            } catch (err) {
              console.error('mergeCart: Failed to merge anon to user cart');
            }
          }
        }
      }
    }
    refetch();
  };

  return {
    addItemToCart,
    cart: {
      data: cart,
      error,
      loading: isLoading && isFetching,
      refetch
    },
    clearAnonCart: clearCartMeta,
    clearCart,
    mergeCart,
    removeItemFromCart,
    updateCartOrderType
  };
};
