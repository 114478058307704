import Link from 'next/link';

import { getSlug, WideBannerType } from 'services';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { MOBILE_WIDTH } from '../../utils/constants';
import { isExternalUrl } from '../../utils/url';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import Close from '../../assets/svgs/close/icon-close.svg';

import './wide-banner.scss';

export type WideBannerProps = {
  widebanner?: WideBannerType[];
  onClose: () => void;
};

export const WideBanner = ({
  widebanner,
  onClose
}: WideBannerProps): JSX.Element | null => {
  const { width } = useSiteWideContext();

  if (!widebanner || !widebanner.length || !widebanner[0]?.link.desktop_text)
    return null;

  const mobileText =
    widebanner[0].link.mobile_text && widebanner[0].link.mobile_text.length
      ? widebanner[0].link.mobile_text
      : widebanner[0].link.desktop_text;

  return (
    <PackageComponentWrapper additionalClass="bg-primary" maxWidth={false}>
      <div
        data-test="wide-banner"
        className="wide-banner-curaleaf d-flex justify-content-between align-items-center">
        <div className="link-container text-center">
          {widebanner[0].link.url && widebanner[0].link.url.length ? (
            <Link
              href={getSlug(widebanner[0].link.url)}
              passHref={isExternalUrl(widebanner[0].link.url)}
              className="text-white body-l"
              target={isExternalUrl(widebanner[0].link.url) ? '_blank' : ''}
              rel="noreferrer">
              {width && width >= MOBILE_WIDTH
                ? widebanner[0].link.desktop_text
                : mobileText}
            </Link>
          ) : (
            <span className="text">
              {width && width >= MOBILE_WIDTH
                ? widebanner[0].link.desktop_text
                : mobileText}
            </span>
          )}
        </div>
        <div
          role="button"
          className="close pointer"
          aria-label="close"
          onClick={onClose}
          tabIndex={0}>
          <Close aria-hidden="true" />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
