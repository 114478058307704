import {
  Consolidate_Product,
  DutchiePlus_Generated_Checkout,
  Consolidate_Cart_V2
} from 'services';

export const dutchieCartToConsolidateCartMap = (
  cart: DutchiePlus_Generated_Checkout,
  dispensaryUniqueId: string
): Consolidate_Cart_V2 => {
  return {
    ...cart,
    dispensaryUniqueId,
    orderType: cart.orderType,
    pricingType: cart.pricingType,
    items: cart.items.map((item) => {
      return {
        basePrice: item.basePrice,
        discounts: item.discounts,
        id: item.id,
        option: item.option,
        product: {
          brand: item.product.brand
            ? {
                name: item.product.brand.name
              }
            : null,
          cardDescription: '', // not needed in cart
          category:
            item.product.subcategory === 'DRINKS' &&
            /* defaults to dutchie's category type instead of consolidate's even though explicitly defined */
            (item.product.category as Consolidate_Product['category']) !==
              'LIQUIDS'
              ? item.product.subcategory
              : item.product.category,
          id: item.product.id,
          images: item.product.images,
          name: item.product.name,
          strainType: item.product.strainType || 'NOT_APPLICABLE',
          subcategory:
            item.product.subcategory === 'DRINKS'
              ? undefined
              : item.product.subcategory,
          variants: item.product.variants.map((v) => {
            // copied from curaql
            const price =
              cart.pricingType === 'MEDICAL' ? v.priceMed : v.priceRec;
            const specialPrice =
              cart.pricingType === 'MEDICAL'
                ? v.specialPriceMed || 0
                : v.specialPriceRec || 0;
            const isSpecial = !!(specialPrice && price != specialPrice);
            const percentOff = isSpecial
              ? 100 - Math.trunc((specialPrice / (price || specialPrice)) * 100)
              : 0;
            return {
              id: v.id,
              option: v.option,
              isSpecial: !!(specialPrice && price != specialPrice),
              percentOff: percentOff ?? 0,
              price: price ?? 0,
              quantity: v.quantity ?? 0,
              specialPrice: specialPrice ?? 0,
              specialConditionIds: v.specialConditionIds,
              specialRewardIds: v.specialRewardIds
            };
          })
        },
        productId: item.productId,
        quantity: item.quantity
      };
    })
  };
};

export const optInBoolean = (optInString: string) => {
  return optInString !== 'subscribed' && optInString !== 'unsubscribed';
};
