'use client';

import { Tooltip } from 'primereact/tooltip';
import { useState } from 'react';

import { DatalayerAnalytics } from 'services';
import { CartSummaryProps } from './index';
import { EmptyCart } from './EmptyCart';
import { OrderTypeSelection } from './OrderType';
import { ProductListTile } from '../ProductListTile';
import { CTAButton } from '../CTAButton';
import { OrderSummary } from '../OrderSummary';
import { useLoyaltyData } from '../../hooks/graphs';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { useStorage } from '../../hooks/useStorage';
import { Alert } from '../Alert';

import InformationIcon from '../../assets/svgs/shared/icon-info.svg';
import CuraleafLogo from '../../assets/svgs/header/full-logo-teal.svg';

export const CartSummary = ({
  enabledLogin,
  shopLink,
  userParam
}: CartSummaryProps): JSX.Element | null => {
  const {
    isLoggedIn,
    setAccountDrawerMode,
    setAccountDrawerVisible,
    userOrderType,
    setUserOrderType,
    cart,
    quantity,
    updateCartOrderType,
    clearCart
  } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  const { slug, uid } = currentPathDispensary;
  const { loyaltyMemberInfo } = useLoyaltyData(isLoggedIn);
  const [platformStorage] = useStorage('ga_platform_storage', '', true);

  const [loading, setLoading] = useState(cart.loading);

  const [showClearCartAlert, setClearCartAlert] = useState(false);

  const orderTypeCheck = () => {
    // Adding a check here because order types are shared on order summary & store drawer
    // and we want to still be able to click delivery in the store drawer without setting orderType
    setLoading(true);

    // If user selected 'PICKUP' but the dispensary only allows 'DELIVERY',
    // switch the order type to 'DELIVERY'
    if (userOrderType === 'PICKUP' && currentPathDispensary?.deliveryOnly) {
      setUserOrderType('DELIVERY');
    }
    // If user selected 'DELIVERY' but the dispensary doesn't allow it and allows 'PICKUP',
    // switch the order type to 'PICKUP'
    else if (
      userOrderType === 'DELIVERY' &&
      !currentPathDispensary?.orderTypes?.includes('DELIVERY') &&
      currentPathDispensary?.orderTypes?.includes('PICKUP')
    ) {
      setUserOrderType('PICKUP');
    }

    // If the cart's order type is different from the user's order type
    // update it to user's order type
    if (
      !(cart.data?.orderType === 'KIOSK' && process.env.IS_KIOSK === 'true') &&
      cart.data?.orderType &&
      updateCartOrderType &&
      userOrderType !== cart.data.orderType
    ) {
      updateCartOrderType
        .mutateAsync({
          dispensaryUniqueId: currentPathDispensary.uid,
          orderType: userOrderType
        })
        .finally(() => {
          setLoading(false);
          onCheckout();
        });
    } else {
      setLoading(false);
      onCheckout();
    }
  };

  const onCheckout = async () => {
    if (cart.data) {
      if (cart.data.redirectUrl.length === 0) {
        alert('this store isnt configured!');
      } else {
        if (cart.data) {
          await DatalayerAnalytics.pushBeginCheckout({
            value: cart.data.priceSummary.subtotal / 100,
            coupon: undefined,
            items: DatalayerAnalytics.mapOrderItems(cart.data.items, {
              dispensaryFriendlyName: currentPathDispensary.friendlyName
            }),
            consolidateDispensary: currentPathDispensary
          });
          let redirectBack = `${window.location.origin}${shopLink}/order-confirmation`;
          if (platformStorage && platformStorage === 'android') {
            const firstName = userParam
              ?.split('&')
              .find((param) => param.includes('firstName'))
              ?.split('=')[1];
            redirectBack += `/android?dispensaryId=${uid}&dispensarySlug=${slug}&firstName=${firstName}`;
          }

          const redirect =
            cart.data.redirectUrl.substring(0, 4) === 'http'
              ? cart.data.redirectUrl
              : `https://${cart.data.redirectUrl}`;

          // If curaleaf accounts are enabled, hides dutchie login
          window.open(
            redirect +
              `${userParam?.length ? userParam + '&' : '?'}r=${redirectBack}${
                enabledLogin ? `&hideLogin=true` : ''
              }`,
            '_self'
          );
        }
      }
    }
  };

  const disclaimer =
    (cart?.data?.items.length && currentPathDispensary?.deliveryDisclaimer) ||
    undefined;

  const checkoutDisabled = !!(
    !cart.data?.items.length || currentPathDispensary?.pauseEcommerce
  );

  return (
    <div className="cart-summary d-flex flex-column">
      {showClearCartAlert ? (
        <Alert
          button={{
            callBackFunc: clearCart
              ? () => clearCart(() => setClearCartAlert(false))
              : undefined,
            text: 'Yes, I am sure'
          }}
          close={() => setClearCartAlert(false)}
          title="Are you sure you want to clear your cart?"
        />
      ) : null}
      <div className="summary-container d-flex">
        <div className="tiles d-flex flex-column">
          <div className="header-container d-flex justify-content-between align-items-end">
            <h2 className="header h3 margin-bottom-0 text-primary">{`Cart (${quantity})`}</h2>
            {cart?.data?.items.length ? (
              <div className="clear-cart-container text-right margin-bottom-4">
                <a
                  tabIndex={0}
                  className="clear-cart body-m text-black pointer underline"
                  onClick={() => setClearCartAlert(true)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setClearCartAlert(true);
                    }
                  }}>
                  Clear Cart
                </a>
              </div>
            ) : null}
          </div>
          {cart?.data?.items.length ? (
            cart.data.items.map((item) => {
              const variant = item.product.variants.find(
                (v) => v.option === item.option
              );
              return (
                <ProductListTile
                  key={item.id}
                  props={{
                    isLoading: false,
                    item,
                    setButtonLoading: setLoading,
                    shopLink,
                    type: 'cart',
                    variant
                  }}
                />
              );
            })
          ) : (
            <EmptyCart />
          )}
        </div>
        <ErrorBoundary component="order-summary">
          <div className="order-summary-wrapper d-flex flex-column">
            {isLoggedIn && loyaltyMemberInfo ? (
              <div className="loyalty-points d-flex justify-content-between bg-specialty-light padding-16">
                <div className="d-flex">
                  <span className="label body-l font-bold text-primary">
                    Loyalty Points
                  </span>
                  <span data-test="tooltip">
                    <Tooltip target=".tooltip-info" position="bottom" />
                    <span
                      className="tooltip-info margin-left-5 margin-right-5"
                      data-pr-tooltip={
                        'Loyalty points are redeemable in store at time of purchase.'
                      }>
                      <InformationIcon data-test="information-icon" />
                    </span>
                  </span>
                  :
                </div>
                <span className="text-primary body-l font-bold subtitle">
                  {loyaltyMemberInfo.totalPointsAvailable}pts
                </span>
              </div>
            ) : null}
            <div
              data-logged-in={!!(isLoggedIn && loyaltyMemberInfo)}
              className="order-summary padding-32 bg-secondary-light d-flex flex-column gap-32 justify-content-between">
              <div className="d-flex flex-column gap-16">
                <CuraleafLogo />
                {process.env.IS_KIOSK !== 'true' ? (
                  <OrderTypeSelection
                    options={{
                      cart,
                      updateCartOrderType
                    }}
                    mode="cart"
                    setLoading={setLoading}
                  />
                ) : null}
                <div className="hairline" />
                <OrderSummary
                  props={{
                    isLoading: false,
                    pricing: cart.data
                      ? {
                          discounts: cart.data.priceSummary.discounts,
                          subtotal: cart.data.priceSummary.subtotal,
                          taxes: cart.data.priceSummary.taxes,
                          total: cart.data.priceSummary.total,
                          fees: cart.data.priceSummary.fees
                        }
                      : undefined,
                    type: 'cart'
                  }}
                />
              </div>
              <div>
                {disclaimer ? (
                  <div className="body-m font-bold text-center text-error-dark margin-top-16 margin-bottom-16">
                    {disclaimer}
                  </div>
                ) : null}
                {enabledLogin &&
                !isLoggedIn &&
                !(process.env.IS_KIOSK === 'true') ? (
                  <div className="signin-btn" data-test="checkout-signin">
                    <CTAButton
                      fullWidth={true}
                      callBackFunc={() => {
                        setAccountDrawerMode('login');
                        setAccountDrawerVisible(true);
                      }}
                      size="lg"
                      text="Sign In"
                      variant="primary"
                    />
                  </div>
                ) : null}
                {!isLoggedIn ? (
                  <div
                    className="checkout-btn margin-top-16 text-center"
                    data-test="checkout-guest">
                    <a
                      className="body-l font-bold underline pointer guest-checkout-link"
                      tabIndex={0}
                      role="button"
                      onClick={orderTypeCheck}
                      data-disabled={checkoutDisabled}
                      aria-disabled={checkoutDisabled}>
                      Continue with guest checkout
                    </a>
                  </div>
                ) : (
                  <div className="checkout-btn" data-test="checkout-guest">
                    <CTAButton
                      fullWidth={true}
                      loading={loading}
                      disabled={checkoutDisabled}
                      callBackFunc={orderTypeCheck}
                      text="Checkout"
                      variant="primary"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};
