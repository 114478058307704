import { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { AuthStatus, DatalayerAnalytics, LoginData, loginUser } from 'services';

import { PackageComponentWrapper } from '../../../PackageComponentWrapper';
import { CTAButton } from '../../../CTAButton';
import {
  FormStatusProps,
  FormStatus
} from '../../../FormBuilder/Account/status';
import { PasswordField } from '../../../FormBuilder/components/Password/password';
import { TextInput } from '../../../FormBuilder/components/TextInput';

import { validationErrorCheck } from '../../../../utils/forms';
import { isEmailValid, isStringValid } from '../../../../utils/validators';
import { useSiteWideContext } from '../../../../hooks/siteWideContext';

export type LoginProps = {
  message?: FormStatusProps;
};

export const LoginForm = ({ message }: LoginProps) => {
  const { mergeCart, setAccountDrawerMode, setAccountDrawerVisible, setUser } =
    useSiteWideContext();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<FormStatusProps['type']>('error');
  const [error, setError] = useState<AuthStatus>({
    text: ''
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnChange: false,
    validate: (data: LoginData) => {
      const errors = {
        email: !data.email
          ? 'Please enter a valid email address.'
          : !isEmailValid(data.email)
            ? 'Invalid email address (e.g. example@email.com).'
            : '',
        password: !data.password
          ? 'Please enter a password.'
          : !isStringValid(data.password, 8, 32)
            ? 'You password must include 8-32 characters.'
            : ''
      };

      return validationErrorCheck(errors);
    },
    onSubmit: async (values: LoginData) => {
      setLoading(true);
      DatalayerAnalytics.pushAccountEvent({
        action: 'attempt_login'
      });
      const submit = await loginUser({
        ...values,
        setError,
        setVisibility: setAccountDrawerVisible,
        contextSetter: setUser,
        setMode: setAccountDrawerMode
      });
      setLoading(false);

      if (!submit) {
        formik.setValues({ ...values, password: '' });
        setStatus('error');
        DatalayerAnalytics.pushAccountEvent({
          action:
            message && message.text.includes('locked')
              ? 'account_locked'
              : 'wrong_password'
        });
      } else {
        mergeCart ? mergeCart(values.email) : null;
        formik.resetForm();
        DatalayerAnalytics.pushAccountEvent({
          action: 'success_login'
        });
      }
    }
  });

  useEffect(() => {
    if (message && message.type && message?.text) {
      setStatus(message.type);
      setError({
        text: message.text,
        link: message.link
      });
    }
  }, [message]);

  return (
    <PackageComponentWrapper>
      <form className="account-form" onSubmit={formik.handleSubmit}>
        <div className="sidebar-header">
          <h2 className="margin-bottom-32 text-primary h3">Sign In</h2>
          {error.text ? <FormStatus {...error} type={status} /> : null}
          <div className="text-primary">
            <span>New to Curaleaf? </span>
            <a
              tabIndex={0}
              className="subtitle-link text-specialty underline pointer hover-underline"
              onClick={() => setAccountDrawerMode('create')}>
              Create an account
            </a>
          </div>
        </div>
        <div className="sidebar-form-padded">
          <TextInput
            title="Email"
            type="email"
            field="email"
            formik={formik}
            required={true}
            autoComplete="email"
          />
          <PasswordField
            field="password"
            formik={formik}
            title="Password"
            required={true}
          />
          <button
            type="button"
            className="forgot-pw pointer"
            onClick={() => setAccountDrawerMode('forgot')}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                setAccountDrawerMode('forgot');
              }
            }}>
            <small className="underline margin-top-16">Forgot password?</small>
          </button>
          <CTAButton
            type="submit"
            disabled={!formik.dirty}
            loading={loading}
            text="Sign In"
            fullWidth={true}
            variant="primary"
          />
        </div>
      </form>
    </PackageComponentWrapper>
  );
};
