import { Dispatch, SetStateAction } from 'react';

import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import {
  Consolidate_Cart_Item_V2,
  Consolidate_Order,
  Consolidate_Product,
  Consolidate_ProductVariant
} from 'services';

import { Loader } from './loader';
import { ProductListTile as Default } from './default';

import './product-list-tile.scss';

export type ProductListTileCartProps = {
  isLoading: false;
  item: Consolidate_Cart_Item_V2;
  setButtonLoading: Dispatch<SetStateAction<boolean>>;
  shopLink: string;
  type: 'cart';
  variant?: Consolidate_ProductVariant;
};

export type ProductListTileOrderProps = {
  isLoading: false;
  item: Consolidate_Order['items'][number];
  shopLink?: string;
  type: 'order';
  isOrderHistory?: boolean;
  variant: Consolidate_Order['items'][number]['product']['variants'][number];
};

export type ProductListTilePopupProps = {
  isLoading: false;
  item: {
    product: Pick<
      Consolidate_Product,
      | 'cardDescription'
      | 'category'
      | 'id'
      | 'images'
      | 'name'
      | 'strainType'
      | 'subcategory' // needed for analytics
    > & {
      brand: null | { name: string };
    };
  };
  shopLink: string;
  type: 'popup';
  variant: Consolidate_ProductVariant;
};

export type LoadingProps = {
  isLoading: true;
};

export type ProductListTileProps =
  | ProductListTileCartProps
  | ProductListTileOrderProps
  | ProductListTilePopupProps;

export const ProductListTile = ({
  props
}: {
  props: ProductListTileProps | LoadingProps;
}): JSX.Element | null =>
  props.isLoading ? (
    <Loader />
  ) : (
    <ErrorBoundary component="product-tile">
      <Default {...props} />
    </ErrorBoundary>
  );
