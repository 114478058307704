'use client';

import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  ACTIVE_REFERRALS,
  AuthUser,
  Curaql_LoyaltyMemberInfo,
  Curaql_LoyaltyReferralInfo,
  Curaql,
  REFERRAL_URL,
  SUBMIT_REFERRAL,
  DatalayerAnalytics
} from 'services';

import { snackbarMessage } from '../../utils/snackbar';

export type AxiosData<T> = {
  data: {
    data: T;
    errors?: any;
  };
};

export type UpdateUserProps = Omit<AuthUser, '_id' | 'optIn'>;

type UpdateUserData = {
  user: Omit<AuthUser, 'brazeId' | 'optIn' | 'optInSms' | '_id'>;
};

// Account Preferences
export const useUserData = () => {
  const updateUser = useMutation({
    mutationFn: (variables: Omit<AuthUser, '_id' | 'optIn'>) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: Curaql.UPDATE_USER,
          variables
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }: AxiosData<UpdateUserData>) => {
      if (data.errors?.length) {
        if (data.errors[0]?.message === 'Failed to add item to cart (2)') {
          Cookies.remove(Curaql.CART_META_KEY);
        } else {
          throw new Error(data.errors[0]?.message);
        }
      } else {
        return data.data.user;
      }
    },
    onError: (error: any) => {
      console.error('updateUser: ', error);
      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'updateUser',
        description: message
      });
      snackbarMessage('Something went wrong, please try again.');
    }
  });
  return {
    updateUser: updateUser.mutateAsync
  };
};

type LoyaltyReferralData = {
  activeLoyaltyReferrals: Curaql_LoyaltyReferralInfo[];
};

type LoyaltyURLData = {
  loyaltyReferralUrl: {
    token: string;
  };
};

type SubmitLoyaltyReferralData = {
  loyaltyReferral: {
    message: string;
    success: boolean;
  };
};

// Loyalty Referrals
export const useLoyaltyReferrals = () => {
  const authToken = Cookies.get('curaleafAccountId');
  const isAuthenticated = authToken?.length;

  const {
    isFetching,
    isLoading,
    data: test,
    refetch: refetchReferrals
  } = useQuery({
    queryKey: ['loyaltyReferrals'],
    queryFn: async (): Promise<Curaql_LoyaltyReferralInfo[] | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: ACTIVE_REFERRALS
          },
          withCredentials: true
        }).then(({ data }: AxiosData<LoyaltyReferralData>) => {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message);
          }
          return data.data.activeLoyaltyReferrals;
        });
      } catch (error) {
        console.error('get loyalty info: ', error);
        return null;
      }
    },
    enabled: !!isAuthenticated
  });

  const {
    isFetching: isURLFetching,
    isLoading: isURLLoading,
    error: urlError,
    data: referralURLData
  } = useQuery({
    queryKey: ['referralToken'],
    queryFn: async (): Promise<string | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: REFERRAL_URL
          },
          withCredentials: true
        }).then(({ data }: AxiosData<LoyaltyURLData>) => {
          if (data.errors?.length) {
            throw new Error(data.errors[0].message);
          }
          if (data.data.loyaltyReferralUrl?.token) {
            return data.data.loyaltyReferralUrl?.token;
          } else {
            throw new Error('200 Error: missing token');
          }
        });
      } catch (error) {
        console.error('get referral token: ', error);
        return null;
      }
    },
    enabled: !!isAuthenticated
  });

  const submitLoyaltyReferrals = useMutation({
    mutationFn: (variables: { emailToRefer: string; path: string }) => {
      return axios({
        url: process.env.CURAQL_BASEURL,
        method: 'post',
        data: {
          query: SUBMIT_REFERRAL,
          variables
        },
        withCredentials: true
      });
    },
    onSuccess: ({ data }: AxiosData<SubmitLoyaltyReferralData>) => {
      if (data.errors?.length) {
        throw new Error(data.errors[0]?.message);
      } else {
        return data.data.loyaltyReferral;
      }
    },
    onError: (error: any) => {
      console.error('submitLoyaltyReferrals: ', error);

      const message = error.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : error.toString();
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'useLoyaltyReferrals',
        description: message
      });
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message.replace('Error: ', '')
          : 'Something went wrong, please try again.'
      );
    }
  });

  return {
    isAuthenticated,
    loyaltyReferrals: {
      referrals: test,
      loading: isFetching && isLoading,
      refetchReferrals
    },
    loyaltyUrl: {
      token: referralURLData,
      urlError,
      urlLoading: isURLLoading && isURLFetching
    },
    submit: {
      submitLoyaltyReferrals,
      submittingReferrals: submitLoyaltyReferrals.isPending
    }
  };
};

// Loyalty
export const useLoyaltyData = (isLoggedIn: boolean) => {
  const {
    isPending,
    data: loyaltyMemberInfo,
    error,
    refetch: refetchLoyaltyInfo
  } = useQuery({
    queryKey: ['loyaltyInfo'],
    queryFn: async (): Promise<Curaql_LoyaltyMemberInfo | null> => {
      try {
        return axios({
          url: process.env.CURAQL_BASEURL,
          method: 'post',
          data: {
            query: Curaql.GET_LOYALTY_DATA
          },
          withCredentials: true
        }).then(
          ({
            data
          }: {
            data: {
              data: { loyaltyMemberInfo: Curaql_LoyaltyMemberInfo };
              errors: any;
            };
          }) => {
            if (data.errors?.length) {
              throw new Error(data.errors[0].message);
            }
            return data.data.loyaltyMemberInfo;
          }
        );
      } catch (error) {
        console.error('get loyalty info: ', error);
        return null;
      }
    },
    enabled: !!isLoggedIn
  });

  const isNewMember = loyaltyMemberInfo
    ? loyaltyMemberInfo?.currentTier?.prestige === 'Member' &&
      loyaltyMemberInfo.tierProgress === 0 &&
      parseInt(loyaltyMemberInfo.totalPointsAvailable) === 0
    : true;

  useEffect(() => {
    const authToken = Cookies.get('curaleafAccountId');
    if (authToken?.length) {
      refetchLoyaltyInfo();
    }
  }, [isLoggedIn]);

  return {
    loyaltyMemberInfo,
    loading: isPending,
    error,
    isNewMember
  };
};
