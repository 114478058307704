import { SmartSortProduct } from '@iheartjane/dm-sdk';

import {
  Consolidate_Dispensary,
  Consolidate_Product,
  Curaql_Offer,
  Curaql_ProductCategory,
  Curaql_ProductOffers,
  DutchiePlus_ProductFragment,
  Maybe
} from 'services';
import { ProductTileProps } from '../components/ProductTile';
import { CategoryTileProps } from '../components/CategoryTile';
import { SpecialTileProps } from '../components/StorefrontCarousel/components/SpecialTile';

export const categoriesMap = (
  categories: Maybe<Curaql_ProductCategory>[]
): Pick<CategoryTileProps, 'title'>[] => {
  return categories?.length
    ? categories.reduce(
        (
          arr: Pick<CategoryTileProps, 'title'>[],
          category: Maybe<Curaql_ProductCategory>
        ) => {
          if (category?.displayName) {
            arr.push({
              title: category.displayName
            });
          }
          return arr;
        },
        []
      )
    : [];
};

export const offersMap = (
  offers: Maybe<Curaql_Offer | Curaql_ProductOffers>[]
): SpecialTileProps[] => {
  return offers?.length
    ? offers.reduce(
        (
          arr: SpecialTileProps[],
          offer: Maybe<Curaql_Offer | Curaql_ProductOffers>
        ) => {
          if (offer) {
            if (offer.id && offer.title) {
              arr.push({
                id: offer.id,
                isLoading: false,
                title: offer.title,
                index: arr.length
              });
            }
          }
          return arr;
        },
        []
      )
    : [];
};

export const productsAnalyticsMap = (
  products: ProductTileProps['product'][],
  handleAnalytics?: (productName: string) => void,
  sponsoredProductsData?: SmartSortProduct[]
): Omit<ProductTileProps, 'analyticsContext' | 'isMobile' | 'shopLink'>[] => {
  return (products || []).reduce(
    (
      arr: Omit<
        ProductTileProps,
        'analyticsContext' | 'isMobile' | 'shopLink'
      >[],
      product
    ) => {
      if (product) {
        let isSponsored = false;
        let sponsoredObject: SmartSortProduct | undefined;
        if (sponsoredProductsData?.length) {
          const matchingSponsoredProduct = sponsoredProductsData.find((spd) => {
            const posIdObj = spd.attributes.pos_product_lookup;
            return Object.keys(posIdObj).find(
              (key) => posIdObj[key] === product.posId
            );
          });
          if (matchingSponsoredProduct) {
            isSponsored = matchingSponsoredProduct.isSponsored;
            sponsoredObject = matchingSponsoredProduct;
          }
        }

        arr.push({
          product,
          isSponsored,
          handleAnalytics,
          isLoading: false,
          ihjObject: sponsoredObject
        });
      }
      return arr;
    },
    []
  );
};

export const productVariantsMap = (
  products: DutchiePlus_ProductFragment[],
  menuType: Consolidate_Dispensary['menuTypes'][number]
) => {
  return products.map((p) => ({
    ...p,
    variants: p.variants.map((variant) => {
      const price =
        (menuType === 'MEDICAL' ? variant.priceMed : variant.priceRec) ?? 0;
      const specialPrice =
        (menuType === 'MEDICAL'
          ? variant.specialPriceMed
          : variant.specialPriceRec) ?? 0;
      const isSpecial = specialPrice !== 0 && price !== specialPrice;

      return {
        ...variant,
        isSpecial,
        price,
        specialPrice
      };
    })
  })) as unknown as Consolidate_Product[];
};
