import { MouseEvent, useEffect, useState } from 'react';
import Image from 'next/image';
import { useParams, usePathname } from 'next/navigation';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';

import { Consolidate_ProductVariant } from 'services';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ProductTileProps } from './index';
import { PriceSection } from '../PriceSection';
import { CTAButton } from '../CTAButton';
import { StrainTag } from './StrainTag';
import { ecommerceSanitizeQuery } from '../../utils/url';
import { imgPath } from '../../utils/imgPath';

import DefaultProductImage from '../../assets/images/product-placeholder.png';
import Arrow from '../../assets/svgs/arrows/chevron-down-dropdown.svg';

export const ProductTile = ({
  handleAnalytics,
  index,
  isMobile,
  product,
  shopLink,
  view = 'grid',
  isSponsored = false,
  ihjObject
}: ProductTileProps) => {
  // using sitewide context instead of currentpath because it's on home page
  const [loading, setLoading] = useState(false);
  const pathname = usePathname();
  const params = useParams();
  const { addItemToCart, selectedDispensary } = useSiteWideContext();
  const [selectedVariant, setSelectedVariant] =
    useState<Consolidate_ProductVariant>();

  useEffect(() => {
    setLoading(true);
    let featuredVariant = product.variants[0];
    if (product.variants.length > 1) {
      const specialVariant = product.variants.find(
        (variant) => variant.isSpecial && variant.specialPrice
      );
      if (specialVariant) {
        featuredVariant = specialVariant;
      }
    }
    setSelectedVariant(featuredVariant);
    setLoading(false);
  }, []);

  const handleAddToCart = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    if (selectedVariant && shopLink && addItemToCart && selectedDispensary) {
      if (ihjObject) {
        clickIHJ();
      }
      addItemToCart({
        dispensaryUniqueId: selectedDispensary.uid,
        retailerId: selectedDispensary.retailerId,
        product,
        quantity: 1,
        variant: selectedVariant,
        analyticsContext: {
          pathname,
          categoryKey: params.categoryKey,
          index
        },
        cb: () => {
          setLoading(false);
          if (isSponsored && ihjObject) {
            clickIHJ();
          }
        }
      });
    }
  };

  const image = product.images[0]?.url || '';
  const variantsLength = product.variants.length;

  // sends impressions to ihj when the product tile is in view
  function impressIHJ() {
    if (ihjObject) {
      ihjObject.impress();
    }
  }

  // for on any interaction with one of the ihj tiles (click name, picture, add to cart)
  function clickIHJ() {
    if (ihjObject) {
      ihjObject.click();
    }
  }

  return (
    <InView
      onChange={(inView) => {
        if (inView) {
          impressIHJ();
        }
      }}
      initialInView={false}>
      <div>
        <div
          className={`product-tile-container view-${view}`}
          data-test="product-tile">
          <StrainTag strain={product.strainType} />
          <div className="tile padding-top-16 padding-left-16 padding-right-16">
            <div className="left-col">
              <a
                tabIndex={0}
                onClick={() => {
                  if (handleAnalytics) {
                    handleAnalytics(product.name || '');
                  }
                  if (ihjObject) {
                    clickIHJ();
                  }
                }}
                href={ecommerceSanitizeQuery(
                  `${shopLink}/products/${product.id}`
                )}
                className="image pointer">
                <Image
                  data-test="default-product-img"
                  className="product-img"
                  src={image ? imgPath(image) : DefaultProductImage}
                  alt={product.name}
                  fill={true}
                  sizes="100%"
                />
              </a>
              <div className="product-details">
                {view === 'grid' ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      {selectedVariant ? (
                        <PriceSection
                          isMobile={isMobile}
                          variant={selectedVariant}
                          type="tile"
                        />
                      ) : null}
                    </div>
                    {product.brand ? (
                      <div data-test="brand" className="margin-top-8">
                        <span
                          className={classNames({
                            'text-black body-l font-medium': !isMobile,
                            'text-input-hover body-sm font': isMobile
                          })}>
                          {product.brand?.name}
                        </span>
                      </div>
                    ) : null}
                    <a
                      tabIndex={0}
                      onClick={() => {
                        if (handleAnalytics) {
                          handleAnalytics(product.name || '');
                        }
                        if (ihjObject) {
                          clickIHJ();
                        }
                      }}
                      href={ecommerceSanitizeQuery(
                        `${shopLink}/products/${product.id}`
                      )}>
                      <h2
                        className={classNames(
                          'margin-top-8 product-name text-black margin-0 ',
                          {
                            'body-xl': !isMobile,
                            'body-m font-medium': isMobile
                          }
                        )}>
                        {product.name}
                      </h2>
                    </a>
                    <div
                      className="moodi-day-rating-overlay margin-top-4"
                      data-productid={product.id}></div>
                    <div className="product-options margin-top-8">
                      {((isMobile && variantsLength > 1) ||
                        variantsLength > 2) &&
                      selectedVariant ? (
                        <div className="variant-dropdown">
                          <Dropdown
                            className={classNames({
                              uppercase: product.category === 'APPAREL'
                            })}
                            value={{
                              label:
                                product.category === 'APPAREL'
                                  ? selectedVariant.option.toUpperCase()
                                  : selectedVariant.option,
                              variant: selectedVariant,
                              dataKey: selectedVariant.option
                            }}
                            onChange={(e) =>
                              setSelectedVariant(e.value.variant)
                            }
                            options={product.variants.map((v) => ({
                              label:
                                product.category === 'APPAREL'
                                  ? v.option.toUpperCase()
                                  : v.option,
                              variant: v,
                              dataKey: v.option
                            }))}
                            loading={loading}
                            optionLabel="label"
                            placeholder={
                              loading ? 'Loading...' : 'Select your option'
                            }
                          />
                        </div>
                      ) : variantsLength > 1 && variantsLength < 3 ? (
                        product.variants.map((v) => {
                          const selected = v.option === selectedVariant?.option;
                          return (
                            <a
                              key={v.option}
                              tabIndex={0}
                              role="button"
                              onClick={() => setSelectedVariant(v)}
                              className={classNames(
                                'option-tag pointer body-l font-bold text-black',
                                {
                                  selected
                                }
                              )}>
                              {v.option}
                            </a>
                          );
                        })
                      ) : (
                        <span className="single-variant body-l font-bold text-black">
                          {product.variants[0]?.option !== 'N/A'
                            ? product.variants[0]?.option
                            : ''}
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {product.brand ? (
                      <div data-test="brand">
                        <span className="text-black body-l font-medium">
                          {product.brand?.name}
                        </span>
                      </div>
                    ) : null}
                    <a
                      tabIndex={0}
                      onClick={() => {
                        if (handleAnalytics) {
                          handleAnalytics(product.name || '');
                        }
                      }}
                      href={ecommerceSanitizeQuery(
                        `${shopLink}/products/${product.id}`
                      )}>
                      <h2 className="product-name text-black margin-0 body-l font-bold">
                        {product.name}
                      </h2>
                    </a>
                    <div
                      className="moodi-day-rating-overlay margin-top-4"
                      data-productid={product.id}></div>
                    <div className="d-flex justify-content-between align-items-center margin-top-4">
                      {selectedVariant ? (
                        <PriceSection
                          isMobile={isMobile}
                          variant={selectedVariant}
                          type="list-tile"
                        />
                      ) : null}
                    </div>
                  </>
                )}
                {product.cardDescription ? (
                  <div className="product-additional-info">
                    <span
                      className={classNames({
                        'body-sm font-medium': !(isMobile && view === 'grid'),
                        'caption font-medium': isMobile && view === 'grid'
                      })}>
                      {product.cardDescription}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex right-col">
              {view === 'list' ? (
                <div>
                  <div className="product-options">
                    {variantsLength > 1 && selectedVariant ? (
                      <div className="variant-dropdown">
                        <Dropdown
                          className={classNames({
                            uppercase: product.category === 'APPAREL'
                          })}
                          value={{
                            label:
                              product.category === 'APPAREL'
                                ? selectedVariant.option.toUpperCase()
                                : selectedVariant.option,
                            variant: selectedVariant,
                            dataKey: selectedVariant.option
                          }}
                          onChange={(e) => setSelectedVariant(e.value.variant)}
                          options={product.variants.map((v) => ({
                            label:
                              product.category === 'APPAREL'
                                ? v.option.toUpperCase()
                                : v.option,
                            variant: v,
                            dataKey: v.option
                          }))}
                          loading={loading}
                          optionLabel="label"
                          placeholder={
                            loading ? 'Loading...' : 'Other options available'
                          }
                          dropdownIcon={(opts) => {
                            return (
                              <span
                                className={
                                  (opts.iconProps as any)[
                                    'data-pr-overlay-visible'
                                  ]
                                    ? 'arrow arrow-up'
                                    : 'arrow arrow-down'
                                }>
                                <Arrow />
                              </span>
                            );
                          }}
                        />
                      </div>
                    ) : variantsLength > 0 &&
                      product.variants[0]?.option !== 'N/A' ? (
                      <span className="single-variant body-l font-medium text-black">
                        {product.variants[0]?.option !== 'N/A'
                          ? product.variants[0]?.option
                          : ''}
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="cart-button-wrapper">
                <CTAButton
                  callBackFunc={(e) => handleAddToCart(e)}
                  text="Add to cart"
                  loading={loading}
                  variant="secondary"
                  fullWidth={true}
                  showCartIcon={true}
                  size={
                    view === 'list'
                      ? 'lg'
                      : view === 'grid' && isMobile
                        ? 'sm'
                        : 'md'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {isSponsored ? (
          <div className="text-center margin-top-5">
            <span className="font-medium body-l text-tertiary-dark">
              Sponsored
            </span>
          </div>
        ) : null}
      </div>
    </InView>
  );
};
