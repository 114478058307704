import { Fragment } from 'react/jsx-runtime';

import { capitalize } from 'services';
import { LoadingSkeleton } from '../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  return (
    <>
      {['subtotal', 'taxes', 'total'].map((ps) => {
        const bold = ps === 'total' ? 'font-bold' : '';
        return (
          <Fragment key={ps}>
            {bold ? <div className="hairline" /> : null}
            <div key={ps}>
              <div className="d-flex justify-content-between text-primary text-primary">
                <span className={`body-l ${bold}`}>
                  {ps === 'total' ? 'Order Total' : capitalize(ps)}
                </span>
                <span className={`body-l font-bold`}>
                  <LoadingSkeleton width="50px" />
                </span>
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
