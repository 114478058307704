import { Dispatch, SetStateAction, useState } from 'react';
import classNames from 'classnames';

import { DutchiePlus_OrderType } from 'services';
import { ORDER_TYPE } from '../../../utils/constants';
import { deliveryTypeCheck } from '../../../utils/ecommerce';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import {
  SiteWideWrapperProps,
  useSiteWideContext
} from '../../../hooks/siteWideContext';
import { LoadingSkeleton } from '../../Loader/Skeleton';

import './order-type.scss';

type StoreDrawerOrderTypeProps = {
  options: {
    updateDrawerType: (type: DutchiePlus_OrderType) => void;
    drawerType: DutchiePlus_OrderType;
  };
  mode: 'storedrawer';
  setLoading?: null;
};

type CartOrderTypeProps = {
  options: Pick<SiteWideWrapperProps, 'cart' | 'updateCartOrderType'>;
  mode: 'cart';
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const OrderTypeSelection = ({
  mode = 'cart',
  options,
  setLoading
}: CartOrderTypeProps | StoreDrawerOrderTypeProps): JSX.Element | null => {
  const [orderTypeLoading, setOTLoading] = useState(false);
  const { selectedDispensary, userOrderType, userMenuType } =
    useSiteWideContext();
  const callbacks = {
    checkout:
      mode === 'cart'
        ? { ...(options as CartOrderTypeProps['options']) }
        : undefined,
    drawer:
      mode === 'storedrawer'
        ? { ...(options as StoreDrawerOrderTypeProps['options']) }
        : undefined
  };

  const handleUpdate = (type: DutchiePlus_OrderType) => {
    if (mode === 'cart' && callbacks.checkout) {
      const { cart, updateCartOrderType } = callbacks.checkout;
      if (cart.data && updateCartOrderType && selectedDispensary) {
        setOTLoading(true);
        setLoading ? setLoading(true) : null;
        updateCartOrderType
          .mutateAsync({
            dispensaryUniqueId: selectedDispensary.uid,
            orderType: type
          })
          .finally(() => {
            setOTLoading(false);
            setLoading ? setLoading(false) : null;
          });
      }
    } else if (mode === 'storedrawer' && callbacks.drawer) {
      callbacks.drawer.updateDrawerType(type);
    }
  };

  const orderTypes = (
    mode === 'cart' && selectedDispensary?.orderTypes
      ? selectedDispensary.orderTypes.reduce(
          (arr: DutchiePlus_OrderType[], ot) => {
            if (ot) arr.push(ot);
            return arr;
          },
          []
        )
      : (['DELIVERY', 'PICKUP'] as DutchiePlus_OrderType[])
  )
    .filter(
      (ot) =>
        (ot.includes('_') && deliveryTypeCheck([ot], userMenuType)) ||
        !ot.includes('_')
    )
    .map((ot) => ({
      displayName: ORDER_TYPE[ot],
      key: ot.includes('_DELIVERY') ? 'DELIVERY' : ot
    }));

  if (
    mode === 'cart' &&
    (orderTypes.length === 1 ||
      !(callbacks.checkout?.cart.data?.items?.length ?? 0))
  ) {
    return null;
  }

  return (
    <PackageComponentWrapper minWidth={false}>
      <div
        className={classNames('order-type-container d-flex sm-border-radius', {
          ['order-type-container-loader']: orderTypeLoading,
          'margin-bottom-20': mode === 'storedrawer'
        })}>
        {orderTypeLoading ? (
          <LoadingSkeleton width="100%" height="100%" />
        ) : (
          orderTypes.map((ot) => {
            if (ot) {
              return (
                <div
                  key={ot.key}
                  tabIndex={0}
                  className={classNames(
                    'order-options body-sm text-center pointer d-flex justify-content-center align-items-center',
                    {
                      'bg-primary text-white':
                        (mode === 'cart' && userOrderType === ot.key) ||
                        (mode === 'storedrawer' &&
                          callbacks.drawer?.drawerType === ot.key),
                      'text-primary':
                        (mode === 'cart' && userOrderType !== ot.key) ||
                        (mode === 'storedrawer' &&
                          callbacks.drawer?.drawerType !== ot.key)
                    }
                  )}
                  onClick={() => handleUpdate(ot.key)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleUpdate(ot.key);
                    }
                  }}>
                  {ot.displayName}
                </div>
              );
            }
          })
        )}
      </div>
    </PackageComponentWrapper>
  );
};
